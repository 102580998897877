import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import * as convertKeys from 'convert-keys';
import {ApiService} from './api.service';
import {Site} from '../model/site.model';

@Injectable()
export class CommoditiesService {
    conflictError = new Subject<any[]>();
    readonly commodities$ = new BehaviorSubject<Site[]>(null);
 
    constructor(private apiService: ApiService) {
    }

    async setCommodities(): Promise<void> {
        try {
            const commodities = await this.apiService.get('commodities');
            this.commodities$.next(convertKeys.toCamel(commodities));
        } catch (err) {
            console.log(`Could not load Types`, err);
            this.commodities$.next([]);
        }
    }
}
