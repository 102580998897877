<div
    ngModelGroup="app-service-delivery-point"
    id="serviceDeliveryPointForm"
    data-automation="serviceDeliveryPointForm"
    [formGroup]="serviceDeliveryPointForm"
>
    <div class="form-group columns-container">
        <!--- SITE --->
        <mat-form-field class="column" appearance="outline" floatLabel="always">
            <mat-label data-automation="siteLabel">{{ 'utility.account.site' | translate }}</mat-label>
            <mat-select
                formControlName="siteId"
                data-automation="site"
                [class.loading-field]="isEditMode ? loadSite : (loadingSites | async)"
                required
                data-url-persist
                id="site"
                placeholder="{{
                    (sites | async).length > 0
                        ? ('utility.account.create.site_selector' | translate)
                        : ('utility.account.create.no_sites' | translate)
                }}"
            >
                <ng-container *ngFor="let site of sites | async">
                    <mat-option [value]="site.id" id="{{ site.id }}">{{ site.displayLabel }} </mat-option>
                </ng-container>
            </mat-select>
            <mat-error *ngIf="siteId.hasError('required')">
                <span data-automation="siteValidationMessage">{{
                    'utility.account.validation.required' | translate
                }}</span>
            </mat-error>
            <mat-hint *ngIf="isEditMode ? loadSite : (loadingSites | async)" align="end">{{
                'utility.account.loading' | translate
            }}</mat-hint>
            <mat-spinner class="select-spinner" *ngIf="isEditMode ? loadSite : (loadingSites | async)" matSuffix diameter="20"></mat-spinner>
        </mat-form-field>
        <!-- NAME -->
        <form #multiLocaleLabels="ngForm" class="mat-form-field column">
            <div class="mat-form-field-container" data-automation="displayLabel">
                <multi-locale-input
                    name="displayLabels"
                    [inputLabel]="nameLabel"
                    [(ngModel)]="multidisplayLabels"
                    [locales]="supportedLocales.value"
                    [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                    [localeKey]="multiLocaleConfig.localeKey"
                    [placeholderText]="namePlaceholder"
                    [defaultLocale]="multiLocaleConfig.defaultLocale"
                    [required]="true"
                    [id]="'sdp_display_labels'"
                    #multiDisplaylabels
                >
                </multi-locale-input>
            </div>
        </form>
        <!-- DEFAULT LOCALE -->
        <mat-form-field class="column" appearance="outline" floatLabel="always">
            <mat-label data-automation="localeLabel">{{ 'utility.account.default_locale' | translate }}</mat-label>
            <mat-select
                required
                formControlName="defaultLocale"
                data-automation="locale"
                placeholder="{{ 'utility.account.create.placeholder.locale_selector' | translate }}"
            >
                <mat-option
                    *ngFor="let supportedLocale of serviceDeliveryPointForm.controls.supportedLocales.value"
                    [value]="supportedLocale.localeName"
                >
                    {{ supportedLocale.displayLabel }}
                </mat-option>
            </mat-select>
            <mat-error class="alert" *ngIf="defaultLocale.hasError('required')">
                <span data-automation="localeValidationMessage">{{
                    'utility.account.validation.required' | translate
                }}</span>
            </mat-error>
        </mat-form-field>

        <!-- SUPPORTED LOCALES -->
        <mat-form-field class="column" appearance="outline" floatLabel="always" data-automation="supportedLocalesField">
            <mat-label data-automation="supportedLocalesLabel">{{
                'utility.account.supported_locales' | translate
            }}</mat-label>
            <mat-select
                formControlName="supportedLocales"
                data-automation="supportedLocales"
                (selectionChange)="handleSupportedLocalesSelection()"
                (openedChange)="supportedLocalesMenuToggled($event)"
                multiple
            >
                <mat-option
                    *ngFor="let locale of locales"
                    [value]="locale"
                    [disabled]="limitLocaleSelectList(5, locale) || onlyLocaleSelected(locale)"
                >
                    {{ locale.displayLabel }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!-- COMMODITY -->
        <mat-form-field class="column" floatLabel="always" appearance="outline">
            <mat-label data-automation="commodityLabel">{{ 'utility.account.commodity' | translate }}</mat-label>
            <mat-select
                required
                formControlName="commodity"
                data-automation="commodity"
                id="commodity"
                placeholder="{{ 'utility.account.create.placeholder.commodity' | translate }}"
            >
                <mat-option [value]="null">{{ 'utility.account.create.placeholder.commodity' | translate }}</mat-option>
                <mat-option *ngFor="let commodity of commodities" [value]="commodity.id">
                    {{ commodity.displayLabel | translate }}
                </mat-option>
            </mat-select>
            <mat-error class="alert" *ngIf="commodity.hasError('required')">
                <span data-automation="commodityValidationMessage">{{
                    'utility.account.validation.required' | translate
                }}</span>
            </mat-error>
        </mat-form-field>

        <!-- TYPE -->
        <mat-form-field class="column" floatLabel="always" appearance="outline">
            <mat-label data-automation="sdpTypeLabel">{{ 'utility.account.type' | translate }}</mat-label>
            <mat-select
                required
                formControlName="sdpType"
                data-automation="sdpType"
                placeholder="{{ 'utility.account.create.placeholder.type' | translate }}"
            >
                <mat-option *ngFor="let type of types; let i = index" [value]="type.name">
                    {{ type.displayLabel | translate }}
                </mat-option>
            </mat-select>
            <mat-error class="alert" *ngIf="sdpType.hasError('required')">
                <span data-automation="typeValidationMessage">{{
                    'utility.account.validation.required' | translate
                }}</span>
            </mat-error>
        </mat-form-field>

        <!-- UTILITY METER NUMBER -->
        <mat-form-field class="column" appearance="outline" floatLabel="always">
            <mat-label data-automation="utilityMeterNumberLabel">{{
                'utility.account.utility_meter_number' | translate
            }}</mat-label>
            <input
                matInput
                id="utilityMeterNumber"
                type="text"
                name="utilityMeterNumber"
                placeholder="{{ 'utility.account.create.placeholder.utility_meter_number' | translate }}"
                formControlName="utilityMeterNumber"
                data-automation="utilityMeterNumber"
                data-url-persist
            />
            <mat-error *ngIf="utilityMeterNumber.hasError('required')">
                <span data-automation="utilityMeterValidationMessage">{{
                    'utility.account.validation.required' | translate
                }}</span>
            </mat-error>
        </mat-form-field>

        <!-- START DATE -->
        <mat-form-field class="column" appearance="outline" floatLabel="always">
            <mat-label data-automation="startDttmLabel">{{ 'utility.account.start_dttm' | translate }}</mat-label>
            <input
                matInput
                required
                [matDatepicker]="startDttmDatepicker"
                placeholder="{{ 'utility.account.create.placeholder.start_dttm' | translate }}"
                formControlName="startDttm"
                data-automation="startDttm"
                (dateChange)="checkDates()"
            />
            <mat-datepicker-toggle matSuffix [for]="startDttmDatepicker">
                <mat-icon matDatepickerToggleIcon fontSet="material-icons-outlined">calendar_today</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #startDttmDatepicker></mat-datepicker>
            <mat-error class="alert" *ngIf="startDttm.hasError('required')">
                <span data-automation="startDttmValidationMessage">{{
                    'utility.account.validation.required' | translate
                }}</span>
            </mat-error>
        </mat-form-field>

        <!-- END DATE -->
        <mat-form-field #endDate class="column" appearance="outline" floatLabel="always">
            <mat-label data-automation="endDttmLabel">{{ 'utility.account.end_dttm' | translate }}</mat-label>
            <input
                matInput
                [min]="getMinDate()"
                [matDatepicker]="endDttmDatepicker"
                placeholder="{{ 'utility.account.create.placeholder.end_dttm' | translate }}"
                formControlName="endDttm"
                data-automation="endDttm"
                (dateChange)="checkDates()"
            />
            <mat-datepicker-toggle matSuffix [for]="endDttmDatepicker">
                <mat-icon matDatepickerToggleIcon fontSet="material-icons-outlined">calendar_today</mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #endDttmDatepicker></mat-datepicker>
            <mat-error class="alert" *ngIf="dateError">
                <span data-automation="endDttmValidationMessage">
                    {{ 'utility.account.validation.end_date' | translate }}
                </span>
            </mat-error>
        </mat-form-field>

        <!-- STATUS -->
        <mat-form-field class="column" appearance="outline">
            <mat-label data-automation="statusLabel">{{ 'utility.account.status' | translate }}</mat-label>
            <mat-select
                required
                formControlName="status"
                data-automation="status"
                placeholder="{{ 'utility.account.create.placeholder.status' | translate }}"
            >
                <mat-option *ngFor="let status of statuses" [value]="status.id">
                    {{ status.display_label | translate }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="status.hasError('required')">
                <span data-automation="statusValidationMessage">{{
                    'utility.account.validation.required' | translate
                }}</span>
            </mat-error>
        </mat-form-field>

        <!-- DESCRIPTION -->
        <form #multiLocaleDescriptionLabels="ngForm" class="mat-form-field column">
            <div class="mat-form-field-container" data-automation="descriptions">
                <multi-locale-input
                    name="descriptions"
                    [inputLabel]="descriptionLabel"
                    [(ngModel)]="multiDescriptionDisplayLabels"
                    [locales]="supportedLocales.value"
                    [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                    [localeKey]="multiLocaleConfig.localeKey"
                    [defaultLocale]="multiLocaleConfig.defaultLocale"
                    [placeholderText]="descriptionPlaceholder"
                    [required]="false"
                    [id]="'sdp_description_display_labels'"
                    #multiDescriptionlabels
                >
                </multi-locale-input>
            </div>
        </form>
    </div>
</div>
