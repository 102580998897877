<div class="app-service-delivery-point-edit content">
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon>edit</mat-icon>
                </div>
                <h1 class="ellipsis" data-automation="edit-title">
                    {{ 'utility.account.edit.title' | translate }} {{ serviceDeliveryPoint?.displayLabel }}
                </h1>
                <p class="desktop-only subtitle" data-automation="edit-subtitle">
                    {{ 'utility.sdp.edit.subtitle' | translate }}
                </p>
            </div>
        </div>

        <div class="right-column">
            <button
                class="icon-btn x-btn-secondary"
                color="gray"
                (click)="handleCancel()"
                [disabled]="this.serviceDeliveryPointId === ''"
                data-automation="serviceDeliveryPointCancel"
            >
                <mat-icon>clear</mat-icon>
                <span class="btn-text"> {{ 'utility.account.button.cancel' | translate }}</span>
            </button>

<!--            <button-->
<!--                class="icon-btn x-btn-primary"-->
<!--                color="accent"-->
<!--                aria-label="delete"-->
<!--                (click)="openDeleteDialog()"-->
<!--                disabled-->
<!--                data-automation="serviceDeliveryPointDelete"-->
<!--            >-->
<!--                <mat-icon>delete</mat-icon>-->
<!--                <span class="btn-text"> {{ 'utility.account.button.delete' | translate }}</span>-->
<!--            </button>-->

            <button
                class="icon-btn x-btn-primary"
                color="accent"
                (click)="handleSubmit()"
                data-automation="serviceDeliveryPointSubmit"
                [disabled]="!serviceDeliveryPointForm.valid"
            >
                <mat-icon *ngIf="!isSubmitting">done</mat-icon>
                <div *ngIf="isSubmitting" class="btn-spinner">
                    <mat-spinner diameter="15"></mat-spinner>
                </div>
                <span class="btn-text"> {{ 'utility.account.button.save' | translate }}</span>
            </button>
        </div>
    </header>

    <form #edit="ngForm" class="service-delivery-point-form">
        <app-service-delivery-point-form
            [mode]="mode"
            [serviceDeliveryPointForm]="serviceDeliveryPointForm"
        ></app-service-delivery-point-form>
    </form>
</div>
